import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { debounce } from "lodash";
import {
  Box,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  SaveOutlined,
  ArrowUpward,
  QueryBuilderOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import api from "lib/api";
import { setFlaggedCourses, setSelectedActivity } from "reducers/user";
import { setSnackbar } from "reducers/ui";

import ActivitiesList from "components/student/ActivitiesList";
import MessageComponent from "components/student/MessageComponent";
import EmbossedCard from "components/student/EmbossedCard";
import TypingIndicator from "components/student/TypingIndicator";
import { useAuth0 } from "@auth0/auth0-react";
import RecommendedChatOptions from "components/student/RecommendedChatOptions";
import ChatInput from "components/student/ChatInput";

const Chat = (props: any) => {
  const { selectedCourse, selectedActivity } = props;

  const history = useHistory();
  const isDesktop = useMediaQuery((theme) =>
    (theme as any)?.breakpoints.up("md"),
  );

  const messageBoxRef = useRef<Element | null>();
  const [memo, setMemo] = useState("");
  const [message, setMessage] = useState("");
  const [chatHistory, setChatHistory] = useState([] as Array<any>);
  const [currentMessage, setCurrentMessage] = useState("");
  const [activities, setActivities] = useState([] as Array<any>);
  const [materials, setMaterials] = useState([] as Array<any>);
  const [showMaterials, setShowMaterials] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [chatLoading, setChatLoading] = useState(false);
  const [activitiesLoading, setActivitiesLoading] = useState(false);
  const [moreLoading, setMoreLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { getAccessTokenSilently } = useAuth0();
  const [hasStartedChat, setHasStartedChat] = useState(false);
  const [materialPreview, setMaterialPreview] = useState<string | null>(null);

  const getChatHistory = async (pageNumber: any, activityId?: string) => {
    if (page && pageNumber > 1) setMoreLoading(true);
    else setChatLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const response = await api.activity.getChatHistory(
        {
          activityId: activityId ? activityId : selectedActivity?.activity?.id,
          page: pageNumber,
          perPage: activityId ? 1 : 10,
        },
        token,
      );

      if (activityId) {
        return response?.data?.chatHistory[0]
          ? response.data.chatHistory[0]
          : {
            coursesActivitiesId: activityId,
            summary: "No summary available.",
          };
      }

      if (response.data.chatHistory.length < 10) {
        setHasMore(false);
      }

      if (pageNumber > 1) {
        setChatHistory((prev) => [...prev, ...response.data.chatHistory]);
      } else {
        setChatHistory(response.data.chatHistory);
        setHasStartedChat(response.data.chatHistory.length > 0);
      }
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setCurrentMessage("");
      if (page && pageNumber > 1) setTimeout(() => setMoreLoading(false), 600);
      else setChatLoading(false);
    }
  };

  const sendMessage = async (message: string) => {
    setChatLoading(true);
    setMessage("");
    setHasStartedChat(true);

    try {
      const token = await getAccessTokenSilently();
      setCurrentMessage("");

      const extractedId = chatHistory.length > 0 ? chatHistory[0].id + 1 : 0;

      const latestChatMessage = {
        id: extractedId,
        coursesActivitiesId: 0,
        pupilsId: props.userId,
        sender: "human",
        message: message,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      setChatHistory((prev) => [
        {
          id: extractedId + 1,
          coursesActivitiesId: 0,
          pupilsId: props.userId,
          sender: "system",
          message: <TypingIndicator />,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        },
        latestChatMessage,
        ...prev,
      ]);

      const response = await api.activity.sendMessage(
        {
          activityId: selectedActivity.activity.id,
          message,
        },
        token,
      );

      if (response.status === 429) {
        const error = await response.json();
        props.setSnackbar({
          open: true,
          message: error.message,
          severity: "error",
        });
        return;
      }

      const reader = response.body?.getReader();
      const decoder = new TextDecoder();
      let buffer = "";

      while (true) {
        if (!reader) throw new Error("Expected a response body but got none");
        const { done, value } = await reader.read();
        if (done) {
          console.log("Stream finished.");
          break;
        }

        buffer += decoder.decode(value, { stream: true });
        const lines = buffer.split("\n");

        for (let i = 0; i < lines.length - 1; i++) {
          try {
            const jsonObject = JSON.parse(lines[i]);
            setCurrentMessage(jsonObject?.message);
          } catch (e: any) {
            console.error("Error parsing JSON:", e);
          }
        }

        buffer = lines[lines.length - 1];
      }
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    } finally {
      setMessage("");
      getChatHistory(1);
      setPage(1);
      setChatLoading(false);
    }
  };

  const onScrollToTop = () => {
    setPage((prev) => {
      getChatHistory(prev + 1);
      return prev + 1;
    });
  };

  useEffect(() => {
    const handleScroll = debounce(() => {
      if (!messageBoxRef.current) return;

      if (messageBoxRef.current.scrollTop < 50 && !moreLoading && hasMore) {
        onScrollToTop();
      }
    }, 150);

    const boxElement = messageBoxRef.current;
    if (boxElement) {
      boxElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (boxElement) {
        boxElement.removeEventListener("scroll", handleScroll);
        handleScroll.cancel();
      }
    };
  }, [messageBoxRef, selectedActivity, moreLoading, hasMore]);

  useEffect(() => {
    if (selectedActivity) {
      if (messageBoxRef.current) messageBoxRef.current.scrollTop = 0;
      setPage(1);
      setHasMore(true);
      getChatHistory(1);
    }
  }, [selectedActivity]);

  useEffect(() => {
    const fetchActivitiesAndMaterials = async () => {
      setActivitiesLoading(true);

      try {
        const token = await getAccessTokenSilently();
        const response = await api.course.get(
          {
            courseId: selectedCourse.id,
          },
          token,
        );

        const a = response.data.activities;
        const m = response.data.materials;

        const summaries = await Promise.all(
          a.map(
            async (activity: any) =>
              await getChatHistory(1, activity.activity.id),
          ),
        );

        setActivities(
          a.map((activity: any) => ({
            ...activity,
            activity: {
              ...activity.activity,
              summary: summaries.find(
                (s) => s?.coursesActivitiesId === activity.activity.id,
              )?.summary,
            },
          })),
        );
        setMaterials(m);

        if (a.length > 0 && !selectedActivity) props.setSelectedActivity(a[0]);
        else if (a.length === 0 && selectedActivity) {
          props.setFlaggedCourses([...props.flaggedCourses, selectedCourse.id]);
          history.goBack();
        }
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          message: e.message,
          severity: "error",
        });
      } finally {
        setActivitiesLoading(false);
      }
    };

    if (selectedCourse) fetchActivitiesAndMaterials();
  }, [selectedCourse]);

  const ChatArea = () => (
    <Box sx={{ position: "relative", height: "100%", width: "100%", mt: 4 }}> {/* Added margin top */}
      {hasStartedChat ? (
        <>
          <Box
            ref={messageBoxRef}
            sx={{
              width: "100%",
              height: "72vh",
              overflowY: "scroll",
              position: "relative",
              display: "flex",
              flexDirection: "column-reverse",
              pt: 2,
            }}
          >
            {chatHistory.map((chat, index) => (
              currentMessage.length > 0 && index === 0 ? (
                <MessageComponent
                  key={`${index}-student-message`}
                  message={currentMessage}
                  isReceived={true}
                />
              ) : (
                <MessageComponent
                  key={index}
                  message={chat.message}
                  isReceived={chat.sender === "system"}
                />
              )
            ))}
          </Box>
          <Box sx={{ width: "100%", p: 2, pb: 0 }}>
            <ChatInput onSendMessage={sendMessage} isLoading={chatLoading} />
          </Box>
        </>
      ) : (
        <Box
          sx={{
            height: "72vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.04)",
            borderRadius: 2,
            position: "relative",
            overflow: "hidden",
            mt: 2,
          }}
        >
          <Box sx={{ position: "relative", zIndex: 2 }}>
            <RecommendedChatOptions onSelectOption={sendMessage} />
          </Box>
        </Box>
      )}

      {moreLoading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            pt: 2,
            zIndex: 1,
          }}
        >
          <CircularProgress sx={{ ml: 1 }} />
        </Box>
      )}
    </Box>
  )

  return (
    <Grid
      container
      sx={{
        pt: 2,
        pl: 2,
        justifyContent: "center",
        height: "90vh",
      }}
      spacing={2}
    >
      <Grid item sm={0} lg={2} />
      <Grid item sm={12} lg={10}>
        {!isDesktop && (
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            {showMaterials ? (
              <Typography
                variant="h6"
                fontFamily="Inter"
                fontWeight="bold"
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={() => setShowMaterials(!showMaterials)}
              >
                Hide Materials
              </Typography>
            ) : (
              <SaveOutlined onClick={() => setShowMaterials(!showMaterials)} />
            )}

            {showActivities ? (
              <Typography
                variant="h6"
                fontFamily="Inter"
                fontWeight="bold"
                onClick={() => setShowActivities(!showActivities)}
                sx={{ cursor: "pointer", textDecoration: "underline" }}
              >
                Hide Activities
              </Typography>
            ) : (
              <QueryBuilderOutlined
                onClick={() => setShowActivities(!showActivities)}
              />
            )}
          </Box>
        )}

        {isDesktop ? (
          <Grid container>
            <Grid item sm={2.5} sx={{ display: materialPreview ? "none" : "" }}>
              {activitiesLoading ? (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
                  <CircularProgress size={70} />
                </Box>
              ) : (
                <ActivitiesList
                  activities={activities}
                  selectedCourse={selectedCourse}
                  memo={memo}
                  setMemo={setMemo}
                />
              )}
            </Grid>
            <Grid item sm={7} sx={{ position: "relative", pr: 1 }}>
              <ChatArea />
            </Grid>
            <Grid
              item
              sm={2}
              md={materialPreview ? 5 : 2.5}
              sx={{ position: "relative" }}
            >
              <EmbossedCard
                materials={materials}
                selectedActivity={selectedActivity}
                materialPreview={materialPreview}
                setMaterialPreview={setMaterialPreview}
              />
            </Grid>
          </Grid>
        ) : (
          <Grid container p={0.1} sx={{ position: "relative" }}>
            {showActivities && (
              <Grid
                item
                xl={3}
                xs={12}
                sm={12}
                sx={{
                  position: "absolute",
                  backgroundColor: "background.default",
                  zIndex: 20,
                  left: 0,
                }}
              >
                {activitiesLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center", mt: 8 }}>
                    <CircularProgress size={70} />
                  </Box>
                ) : (
                  <ActivitiesList
                    activities={activities}
                    selectedCourse={selectedCourse}
                    memo={memo}
                    setMemo={setMemo}
                  />
                )}
              </Grid>
            )}
            <Grid
              item
              xl={6}
              xs={12}
              sm={12}
              sx={{
                position: "relative",
              }}
            >
              <ChatArea />
            </Grid>

            {showMaterials && (
              <Grid item lg={materialPreview ? 6 : 3} xs={12}>
                <EmbossedCard
                  materials={materials}
                  selectedActivity={selectedActivity}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  userId: state.user.id,
  token: state.user.token,
  selectedCourse: state.user.selectedCourse,
  selectedActivity: state.user.selectedActivity,
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
  flaggedCourses: state.user.flaggedCourses,
});

const mapDispatchToProps = {
  setSnackbar,
  setSelectedActivity,
  setFlaggedCourses,
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
