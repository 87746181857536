import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";

import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import { clearUser, setSelectedActivity } from "reducers/user";
import ParagraphSection from "components/ParagraphSection";
import UploadMaterialModal from "components/modals/UploadMaterialModal";
import StudentTable from "components/teacher/StudentTable";
import MaterialList from "components/teacher/MaterialList";
import ActivityItem from "components/teacher/ActivityItem";
import Insight from "components/teacher/Insight";
import AppLine from "components/teacher/AppLine";
import SchoolCode from "components/teacher/SchoolCode";
import { useAuth0 } from "@auth0/auth0-react";

const CourseView = (props: any) => {
  const { currentSchoolId, currentClassroomTeacherId } = props;
  const location = useLocation();
  const history = useHistory();
  const { getAccessTokenSilently } = useAuth0();
  const { courseId } = useParams<{ courseId: string }>();

  const [course, setCourse] = useState<CourseResponse | undefined>();
  const [materialDialogOpen, setMaterialDialogOpen] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<
    BaseCourseMaterialID | undefined
  >();
  const [loading, setLoading] = useState(false);

  const toggleMaterialDialogOpen = () =>
    setMaterialDialogOpen(!materialDialogOpen);

  const handleAddActivity = () => {
    history.push(`${location.pathname}/activity/add`);
  };

  useEffect(() => {
    props.setSelectedActivity();

    const fetchCourse = async () => {
      setLoading(true);
      const token = await getAccessTokenSilently();
      try {
        const response = await api.course.get(
          {
            courseId,
          },
          token,
        );
        setCourse(response.data);
      } catch (e: any) {
        if (e.response?.status === 403) {
          props.setSnackbar({
            open: true,
            message: e.response.data.message || "You don't have permission to access this course.",
            severity: "error",
          });
          history.push('/courses');
          return;
        } else {
          props.setSnackbar({
            open: true,
            message: e.response?.data?.message || "An error occurred while loading the course",
            severity: "error",
          });
        }
      } finally {
        setLoading(false);
      }
    };

    if (courseId) fetchCourse();
    // eslint-disable-next-line
  }, [courseId]);

  return (
    <Grid container sx={{ pt: 2, px: 2, mb: 2, justifyContent: "center" }}>

      <Grid item xs={12} lg={3} sx={{ justifyContent: "center", width: 400 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "100%",
            overflow: "hidden",
            px: 2,
          }}
        >
          {/* Analytics Button */}
          <Box sx={{
            position: 'relative',
            width: 'fit-content',
            mb: 0.5,
            mt: 1
          }}>
            <Button
              variant="outlined"
              onClick={() => history.push(`${location.pathname}/report`)}
              sx={{
                borderRadius: 1,
                backgroundColor: 'background.paper',
                ':hover': {
                  backgroundColor: 'primary.weak',
                },
              }}
            >
              <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
                View Class Analytics
              </Typography>
            </Button>
            <Box
              sx={{
                position: 'absolute',
                top: -8,
                right: -16,
                backgroundColor: 'warning.main',
                borderRadius: 1,
                padding: '2px 6px',
                transform: 'scale(0.8)',
              }}
            >
              <Typography
                fontFamily="Inter"
                fontSize={10}
                fontWeight="bold"
                color="white"
              >
                BETA
              </Typography>
            </Box>
          </Box>
          <AppLine color="inputs.main" />

          {/* Activities Section */}
          <Typography fontFamily="Inter" fontSize={22} sx={{ mt: 0.5 }}>
            Activities
          </Typography>
          <Button
            variant="contained"
            startIcon={<Add />}
            sx={{
              borderRadius: 1,
              width: "fit-content",
            }}
            onClick={handleAddActivity}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              Add Activity
            </Typography>
          </Button>
          {course?.activities.length !== 0 && (
            <Box>
              <AppLine color="inputs.main" />
            </Box>
          )}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "flex-end",
              }}
            >
              {Array.from({ length: 4 }, (_, index) => (
                <Skeleton
                  key={index}
                  variant="rectangular"
                  sx={{ width: "100%", height: 50 }}
                />
              ))}
            </Box>
          ) : (
            course?.activities.map((item: any, index: any) => (
              <ActivityItem item={item} key={index} />
            ))
          )}
          <Box>
            <AppLine color="inputs.main" />
          </Box>
          <SchoolCode />
          <Box>
            <AppLine color="inputs.main" />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <MaterialList
              setSelectedMaterial={setSelectedMaterial}
              toggleOpenDialog={toggleMaterialDialogOpen}
              materials={
                course?.materials.filter((material) => !material.activityId) ??
                []
              }
              onRemoveMaterial={async (material) => {
                if (!course) throw new Error("expected a course but got none");
                await api.material.delete(
                  { materialId: material.id.toString() },
                  await getAccessTokenSilently(),
                );
                setCourse((course) => ({
                  ...course!,
                  materials: course!.materials.filter(
                    (el) => el.id !== material.id,
                  ),
                }));
              }}
            />
          </Box>
          <Button
            variant="contained"
            onClick={toggleMaterialDialogOpen}
            startIcon={<Add />}
            sx={{
              width: "fit-content",
              borderRadius: 1,
            }}
          >
            <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
              Upload Class Materials
            </Typography>
          </Button>
          <Insight>
            <ParagraphSection
              title="Activities"
              description=" These are the heart of Ellie. Activities form the in class coursework for the day. They’re like worksheets but far more powerful  with a built in assistant."
            />

            <ParagraphSection
              title="Adding Students"
              description="Need help adding students? "
            />
            <Typography
              fontWeight="bold"
              fontFamily="Inter"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Contact the Ellie Support Team for help.
            </Typography>

            <ParagraphSection
              title="Click on a Student Name to add notes"
              description="Ellie can adapt to each student. Add a brief note about student interests and learning styles."
            />
            <Typography
              fontWeight="bold"
              fontFamily="Inter"
              sx={{
                color: "primary.main",
                cursor: "pointer",
                textDecoration: "underline",
              }}
            >
              Learn more about this feature.
            </Typography>
          </Insight>
        </Box>
      </Grid>

      <Grid item xs={12} lg={5} sx={{ px: { xs: 2, lg: 0 } }}>
        <StudentTable noRefresh />
      </Grid>

      <UploadMaterialModal
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
        courseId={courseId}
        open={materialDialogOpen}
        onClose={toggleMaterialDialogOpen}
        concatMaterials={(materials: BaseCourseMaterialID[]) => {
          if (!course) throw new Error("expected course but got none.");
          setCourse((course) => ({
            ...course!,
            materials: course!.materials.concat(materials),
          }));
        }}
      />
    </Grid>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSelectedActivity,
  setSnackbar,
  clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(CourseView);
