import { authInstance, createAuthHeaders } from "lib/api/utils";

const activityGetChatHistory = (
  {
    activityId,
    perPage = 10,
    page = 1,
  }: { activityId: string; perPage: number; page: number },
  token: string,
) => {
  return authInstance().get<CourseActivityPupilChatHistoryResponse>(
    `/activities/${activityId}/chat/?per_page=${perPage}&page=${page}`,
    createAuthHeaders(token),
  );
};

const activityGetStudentChatHistory = (
  {
    activityId,
    studentId,
    perPage = 10,
    page = 1,
  }: { activityId: string; studentId: string; perPage: number; page: number },
  token: string,
) => {
  return authInstance().get<CourseActivityPupilChatHistoryResponse>(
    `/activities/${activityId}/chat/${studentId}?per_page=${perPage}&page=${page}`,
    createAuthHeaders(token),
  );
};

const activitySendMessage = async (
  { activityId, message }: { activityId: string; message: string },
  token: string,
) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}activities/${activityId}/chat/`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ message }),
    },
  );
};

const activityUpdate = (
  {
    activityId,
    name,
    description,
    promptInstructions,
    llmRolesId,
  }: {
    activityId: string;
    name: string;
    promptInstructions: string;
    llmRolesId: string;
    description: string;
  },
  token: string,
) => {
  return authInstance().patch<CourseActivityResponse>(
    `/activities/${activityId}`,
    {
      name,
      description,
      promptInstructions,
      llmRolesId,
    },
    createAuthHeaders(token),
  );
};

const activityUpdateObjectives = (
  {
    activityId,
    objectives,
  }: { activityId: string; objectives: { id: string; details?: string }[] },
  token: string,
) => {
  return authInstance().patch<CourseActivityResponse>(
    `/activities/${activityId}/objectives`,
    { objectives },
    createAuthHeaders(token),
  );
};
const deleteCourseActivity = (
  { activityId }: { activityId: string },
  token: string,
) => {
  return authInstance().delete<DBCourseActivity>(
    `/activities/${activityId}`,
    createAuthHeaders(token),
  );
};
const activityAddObjectiveOrQuestion = (
  {
    activityId,
    objectives,
    questions,
  }: CourseActivityAddObjectivesAndQuestionsRequest & { activityId: string },
  token: string,
) => {
  return authInstance().put<CourseActivityResponse>(
    `/activities/${activityId}`,
    {
      objectives,
      questions,
    },
    createAuthHeaders(token),
  );
};

const activityGetObjectives = async (
  { activityId }: { activityId: string },
  token: string,
) => {
  return authInstance().get<Array<DBCourseActivityObjective>>(
    `/activities/${activityId}/objectives`,
    createAuthHeaders(token),
  );
};

const activityGetMaterials = async (
  { activityId }: { activityId: string },
  token: string,
) => {
  return authInstance().get<Array<DBCourseMaterial>>(
    `/activities/${activityId}/materials`,
    createAuthHeaders(token),
  );
};

const activity = {
  getChatHistory: activityGetChatHistory,
  sendMessage: activitySendMessage,
  update: activityUpdate,
  updateObjectives: activityUpdateObjectives,
  addObjectiveOrQuestion: activityAddObjectiveOrQuestion,
  delete: deleteCourseActivity,
  getStudentChatHistory: activityGetStudentChatHistory,
  getObjectives: activityGetObjectives,
  getMaterials: activityGetMaterials,
};

export default activity;
