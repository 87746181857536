import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { ChevronLeft } from "@mui/icons-material";

import { setSelectedActivity } from "reducers/user";
import ObjectiveWithProgress from "components/teacher/ObjectiveWithProgress";
// @ts-expect-error TS(2307): Cannot find module '../../assets/tripple-dot-icon.... Remove this comment to see the full error message
import { ReactComponent as TrippleDotIcon } from "../../assets/tripple-dot-icon.svg";

const ActivityItem = (props: any) => {
  const {
    refreshing,
    selectedActivity,
    item: activity,
    open: forceOpen,
    objectivesProgress,
    objectivesProgressLoading,
    sentimentProgress,
    sentimentProgressLoading,
  } = props;
  const location = useLocation();
  const history = useHistory();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(forceOpen);
  }, [forceOpen]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        gap: 2,
        p: 2,
        backgroundColor: open ? "primary.contrastText" : "primary.weak",
        borderRadius: 2,
        width: "100%",
        cursor: !selectedActivity ? "pointer" : "",
        transition: "opacity 0.3s ease-in-out",
        ":hover": {
          backgroundColor:
            !selectedActivity && !open ? "primary.weakHover" : "",
          boxShadow: !selectedActivity ? "0px 0px 10px rgba(0, 0, 0, 0.2)" : "",
        },
      }}
      onClick={() => {
        if (!selectedActivity) {
          props.setSelectedActivity(activity);
          history.push(`${location.pathname}/activity/${activity.activity.id}`);
        }
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
          <TrippleDotIcon />
          <Typography fontFamily="Inter" fontSize={14}>
            {activity.activity.name}
          </Typography>
        </Box>
        {!forceOpen && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Button
              onClick={(e) => {
                e.stopPropagation();
                props.setSelectedActivity(activity);
                history.push(
                  `${location.pathname}/activity/${activity.activity.id}/edit`,
                );
              }}
              variant="outlined"
              sx={{
                borderRadius: 1,
                px: 1,
                minWidth: 50,
                ":hover": {
                  backgroundColor: "primary.weak",
                },
              }}
            >
              <Typography fontFamily="Inter" fontSize={12} fontWeight="bold">
                Edit
              </Typography>
            </Button>
            <IconButton
              onClick={(e) => {
                setOpen((prev) => !prev);
                e.stopPropagation();
              }}
            >
              <ChevronLeft
                color="primary"
                sx={{ transform: open ? "rotate(-90deg)" : "" }}
              />
            </IconButton>
          </Box>
        )}
      </Box>
      {open && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            transition: "opacity 0.3s ease-in-out",
          }}
        >
          {activity?.objectives?.map((objective: any, index: any) =>
            selectedActivity ? (
              <ObjectiveWithProgress
                key={index}
                description={objective.details}
                loading={objectivesProgressLoading}
                classProgress={objectivesProgress[objective.id]?.completed || 0}
                totalStudents={objectivesProgress[objective.id]?.total || 0}
              />
            ) : (
              <Typography fontFamily="Inter" fontSize={14} my={1}>
                <strong>Objective:</strong> {objective.details}
              </Typography>
            ),
          )}
          {selectedActivity && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                pl: 4,
              }}
            >
              <Box
                sx={{ display: "flex", gap: 2, alignItems: "center", my: 2 }}
              >
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: "graph.completed",
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Completed
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Box
                  sx={{
                    width: 20,
                    height: 10,
                    backgroundColor: "graph.incomplete",
                  }}
                />
                <Typography fontFamily="Inter" fontSize={14}>
                  Not Completed
                </Typography>
              </Box>
            </Box>
          )}
          {/* {selectedActivity && <SentimentPieChart />} */}
        </Box>
      )}
    </Box>
  );
};

const mapStateToProps = (state: any) => ({
  selectedActivity: state.user.selectedActivity,
});

const mapDispatchToProps = {
  setSelectedActivity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItem);
