import React, { useEffect, SyntheticEvent } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import {
  Snackbar,
  Alert,
  Box,
  Typography,
  Button,
  SnackbarCloseReason,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import * as Sentry from "@sentry/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import theme from "theme";
import { setSnackbar } from "reducers/ui";
import { clearUser } from "reducers/user";
import Loading from "components/Loading";

import NavigationLayout from "layouts/Navigation";
import UserLayout from "layouts/User";

import Landing from "pages/auth/Landing";
import WelcomePage from "pages/auth/QR";
import SignUpPage from "pages/auth/SignUp";
import LoginPage from "pages/auth/Login";
import ForgotPasswordPage from "pages/auth/ForgotPassword";
import Auth0Callback from "pages/auth/Auth0Callback";

import AdminLoginPage from "pages/auth/admin/Login";
import AdminAdminPage from "pages/admin/AdminPage";
import AdminClassroomPage from "pages/admin/ClassroomPage";
import AdminCoursePage from "pages/admin/CoursePage";
import AdminDashboardPage from "pages/admin/DashboardPage";
// import AdminPlanPage from 'pages/admin/PlanPage'
import AdminRolePage from "pages/admin/RolePage";
import AdminSchoolAdminPage from "pages/admin/SchoolAdminPage";
import AdminSchoolPage from "pages/admin/SchoolPage";
import AdminStudentPage from "pages/admin/StudentPage";
import AdminTeacherPage from "pages/admin/TeacherPage";
import StudentCreateAccountAfterAuth0 from "pages/auth/student/CreateAccountAfterAuth0";

import GetStarted from "pages/GetStarted";
import GetStartedWithCode from "pages/GetStartedwithCode";
import StudentCourseList from "pages/student/CourseList";
import StudentChat from "pages/student/Chat";
import InstructorCourseList from "pages/instructor/course/List";
import InstructorCreateCourse from "pages/instructor/course/Create";
import InstructorCourseView from "pages/instructor/course/View";
import InstructorAddActivity from "pages/instructor/activity/Add";
import InstructorActivityDashboard from "pages/instructor/activity/Dashboard";
import InstructorCourseViewReport from "pages/instructor/course/ViewReport";

import { useAuth0 } from "@auth0/auth0-react";
import { getAuth0Role } from "utils/auth0";

const getAuth0TokenKey = (clientId: string) =>
  `@@auth0spajs@@::${clientId}::http://myta::openid profile email offline_access`;

const getAuth0UserKey = (clientId: string) =>
  `@@auth0spajs@@::${clientId}::@@user@@`;

const App = (props: any) => {
  const { token, role, master, loading, snackbar } = props;
  const { isAuthenticated, isLoading, user } = useAuth0();

  useEffect(() => {
    // get auth0 stored token data
    const auth0TokenData = localStorage.getItem(getAuth0TokenKey(process.env.REACT_APP_AUTH0_CLIENT_ID!));
    const auth0UserData = localStorage.getItem(getAuth0UserKey(process.env.REACT_APP_AUTH0_CLIENT_ID!));

    console.log('Auth State:', {
      auth0: {
        isLoading,
        isAuthenticated,
        hasAccessToken: !!auth0TokenData,
        hasUserData: !!auth0UserData,
        accessTokenExpiry: auth0TokenData ? JSON.parse(auth0TokenData).expiresAt : null
      },
      redux: {
        hasToken: !!token,
        tokenValue: token?.substring(0, 10) + '...' // just show start of token
      },
      timeInfo: {
        currentTime: Math.floor(Date.now() / 1000),
        tokenExpiresIn: auth0TokenData ?
          JSON.parse(auth0TokenData).expiresAt - Math.floor(Date.now() / 1000) : null
      }
    });

    if (!isLoading && !isAuthenticated && token) {
      props.clearUser();
      localStorage.clear();
    }
  }, [isLoading, isAuthenticated, token]);

  useEffect(() => {
    const checkTokenExpiration = () => {
      const auth0TokenData = localStorage.getItem(getAuth0TokenKey(process.env.REACT_APP_AUTH0_CLIENT_ID!));
      if (auth0TokenData) {
        const { expiresAt } = JSON.parse(auth0TokenData);
        const currentTime = Math.floor(Date.now() / 1000);

        // if token is expired or about to expire in next 5 minutes
        if (expiresAt - currentTime < 300) {
          console.log('Token expired or expiring soon - clearing state');
          props.clearUser();
          localStorage.clear();
          window.location.reload(); // force a clean reload
        }
      }
    };

    // check on component mount and every 5 minutes
    checkTokenExpiration();
    const interval = setInterval(checkTokenExpiration, 5 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  let routes = null;

  // wait for Auth0 to finish loading
  if (isLoading) {
    return <Loading loading={true} backdrop />;
  }

  const auth0role = user ? getAuth0Role(user) : null;

  if (token && isAuthenticated) {
    if (!auth0role) {
      routes = (
        <Switch>
          <Route path="/student/create">
            <StudentCreateAccountAfterAuth0 />
          </Route>
          <Route path="/auth0-callback">
            <Auth0Callback />
          </Route>
          <Redirect to="/" />
        </Switch>
      );
    } else {
      switch (auth0role) {
        case "super_admin":
          routes = (
            <Switch>
              <Route exact path="/">
                <NavigationLayout>
                  <AdminDashboardPage adminType="Super" />
                </NavigationLayout>
              </Route>
              {master && (
                <Route path="/admins">
                  <NavigationLayout>
                    <AdminAdminPage />
                  </NavigationLayout>
                </Route>
              )}
              <Route path="/classrooms">
                <NavigationLayout>
                  <AdminClassroomPage />
                </NavigationLayout>
              </Route>
              <Route path="/courses">
                <NavigationLayout>
                  <AdminCoursePage />
                </NavigationLayout>
              </Route>
              {/* <Route path="/plans">
                <NavigationLayout>
                  <AdminPlanPage />
                </NavigationLayout>
              </Route> */}
              <Route path="/roles">
                <NavigationLayout>
                  <AdminRolePage />
                </NavigationLayout>
              </Route>
              <Route path="/schools">
                <NavigationLayout>
                  <AdminSchoolPage />
                </NavigationLayout>
              </Route>
              <Route path="/school-admins">
                <NavigationLayout>
                  <AdminSchoolAdminPage />
                </NavigationLayout>
              </Route>
              <Route path="/students">
                <NavigationLayout>
                  <AdminStudentPage />
                </NavigationLayout>
              </Route>
              <Route path="/teachers">
                <NavigationLayout>
                  <AdminTeacherPage />
                </NavigationLayout>
              </Route>
              <Redirect to="/" />
            </Switch>
          );
          break;
        case "school_admin":
          routes = (
            <Switch>
              <Route exact path="/">
                <NavigationLayout>
                  <AdminDashboardPage adminType="School" />
                </NavigationLayout>
              </Route>
              <Route path="/classrooms">
                <NavigationLayout>
                  <AdminClassroomPage />
                </NavigationLayout>
              </Route>
              <Route path="/courses">
                <NavigationLayout>
                  <AdminCoursePage />
                </NavigationLayout>
              </Route>
              <Route path="/schools">
                <NavigationLayout>
                  <AdminSchoolPage />
                </NavigationLayout>
              </Route>
              <Route path="/school-admins">
                <NavigationLayout>
                  <AdminSchoolAdminPage />
                </NavigationLayout>
              </Route>
              <Route path="/students">
                <NavigationLayout>
                  <AdminStudentPage />
                </NavigationLayout>
              </Route>
              <Route path="/teachers">
                <NavigationLayout>
                  <AdminTeacherPage />
                </NavigationLayout>
              </Route>
              <Redirect to="/" />
            </Switch>
          );
          break;
        case "teacher":
          routes = (
            <Switch>
              <Route path="/get-started">
                <UserLayout>
                  <GetStarted />
                </UserLayout>
              </Route>
              <Route path="/classes">
                <UserLayout>
                  <InstructorCourseList />
                </UserLayout>
              </Route>
              <Route exact path="/class/create">
                <UserLayout>
                  <InstructorCreateCourse />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId">
                <UserLayout>
                  <InstructorCourseView />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId/edit">
                <UserLayout>
                  <InstructorCreateCourse />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId/report">
                <UserLayout>
                  <InstructorCourseViewReport />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId/activity/add">
                <UserLayout>
                  <InstructorAddActivity />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId/activity/:activityId">
                <UserLayout>
                  <InstructorActivityDashboard />
                </UserLayout>
              </Route>
              <Route exact path="/class/:courseId/activity/:activityId/edit">
                <UserLayout>
                  <InstructorAddActivity />
                </UserLayout>
              </Route>
              <Route path="/auth0-callback">
                <Auth0Callback />
              </Route>
              <Redirect to="/classes" />
            </Switch>
          );
          break;
        case "pupil":
          routes = (
            <Switch>
              <Route path="/get-started">
                <UserLayout>
                  <GetStartedWithCode />
                </UserLayout>
              </Route>
              <Route path="/classes">
                <UserLayout>
                  <StudentCourseList />
                </UserLayout>
              </Route>
              <Route path="/chat">
                <UserLayout>
                  <StudentChat />
                </UserLayout>
              </Route>
              <Redirect to="/classes" />
            </Switch>
          );
          break;
        default:
          /* token and isAuthenticated are true, but
        routes when user has no role, role hasn't loaded, etc. */
          routes = (
            <Switch>
              <Route exact path="/">
                <Landing />
              </Route>
              <Route exact path="/login">
                <LoginPage />
              </Route>
              <Route path="/sign-up">
                <SignUpPage />
              </Route>
              <Route path="/forgot-password">
                <ForgotPasswordPage />
              </Route>
              <Route path="/QR">
                <WelcomePage />
              </Route>
              <Route path="/admin/login">
                <AdminLoginPage />
              </Route>
              <Route path="/student/create">
                <StudentCreateAccountAfterAuth0 />
              </Route>
              <Route path="/auth0-callback">
                <Auth0Callback />
              </Route>
              <Redirect to="/" />
            </Switch>
          );
          break;
      }
    }
  } else {
    // routes when not authenticated at all
    routes = (
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        {/* <Route exact path="/login">
          <LoginPage />
        </Route> 
        <Route path="/sign-up">
          <SignUpPage />
        </Route>
        <Route path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route path="/QR">
          <WelcomePage />
        </Route>
        <Route path="/admin/login">
          <AdminLoginPage />
        </Route>*/}
        <Route path="/student/create">
          <StudentCreateAccountAfterAuth0 />
        </Route>
        {/*<Route path="/instructor/create">
          <InstructorCreateAccount />
        </Route> */}
        <Route path="/auth0-callback">
          <Auth0Callback />
        </Route>
        <Redirect to="/" />
      </Switch>
    );
  }

  const handleSnackbarClose = (
    e: Event | SyntheticEvent<Element, Event>,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === "clickaway") return;
    props.setSnackbar({ open: false });
  };

  return (
    <Sentry.ErrorBoundary fallback={SentryFallbackComponent}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>{routes}</Router>
          <Loading loading={loading} backdrop />
          <Snackbar
            open={snackbar.open}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbar.severity}
              sx={{ width: "100%" }}
            >
              {snackbar.message}
            </Alert>
          </Snackbar>
        </ThemeProvider>
      </LocalizationProvider>
    </Sentry.ErrorBoundary>
  );
};

const SentryFallbackComponent = () => (
  <Box
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box>
      <Typography variant="h4">Oops! An error occured.</Typography>
      <Typography sx={{ mt: 2 }}>
        This error has been reported and will be addressed as soon as possible.
      </Typography>
      <Button
        variant="outlined"
        sx={{ mt: 4 }}
        onClick={() => {
          window.location.reload();
          return false;
        }}
      >
        Reset
      </Button>
    </Box>
  </Box>
);

const mapStateToProps = (state: any) => ({
  token: state.user.token,
  role: state.user.role,
  master: state.user.master,
  loading: state.ui.loading,
  snackbar: state.ui.snackbar,
});

const mapDispatchToProps = {
  clearUser,
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);