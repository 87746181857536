import React, { useState } from "react";
import { Box, Grid, IconButton, Typography } from "@mui/material";
import { Clear, Delete } from "@mui/icons-material";
import DeleteModal from "components/modals/DeleteModal";
import api from "lib/api";
import { useAuth0 } from "@auth0/auth0-react";
import { setSnackbar } from "reducers/ui";
import { connect } from "react-redux";

const MaterialItem = (props: any) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { getAccessTokenSilently } = useAuth0();

  return (
    <Grid item xs={12} sx={{ my: 1 }}>
      <Box
        onClick={props.onClick}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
          padding: 2,
          backgroundColor: "card.main",
          borderRadius: 2,
          cursor: "pointer",
          overflow: "hidden",
          ":hover": {
            backgroundColor: "card.mainHover",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
          },
        }}
      >
        <Box>
          <Typography fontFamily="Inter" fontWeight="bold" fontSize={14}>
            {props.material.name}
          </Typography>
        </Box>

        <IconButton
          onClick={(e) => {
            e.stopPropagation();
            props.onRemove(props.material);
          }}
          size="small"
        >
          <Clear />
        </IconButton>
      </Box>
      <DeleteModal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        itemTitle={props.material.name}
        deleteFunction={async () => {
          setDeleteModalOpen(false);
          await api.material
            .delete(
              { materialId: props.material.id },
              await getAccessTokenSilently(),
            )
            .then(() => {
              props.onRemove();
              props.setSnackbar({
                severity: "success",
                message: "Successfully deleted material",
              });
            });
        }}
      />
    </Grid>
  );
};

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(null, mapDispatchToProps)(MaterialItem);
