import { instance } from "lib/api/utils";

interface MetabaseEmbedResponse {
  embed_url: string;
}

const getCourseEmbedUrl = (
  dashboardId: number,
  courseId: string,
  token: string,
) => {
  return instance.get<MetabaseEmbedResponse>(
    `/metabase/embed/dashboard/${dashboardId}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        course_id: courseId,
      },
    },
  );
};

const metabase = {
  getCourseEmbedUrl,
};

export default metabase;
