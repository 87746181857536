import { authInstance, createAuthHeaders } from "lib/api/utils";
const noteGet = (
  { courseId, studentId }: { courseId: string; studentId: string },
  token: string,
) => {
  return authInstance().get<CoursePupilNoteResponse>(
    `/courses/${courseId}/notes/${studentId}`,
    createAuthHeaders(token),
  );
};

const noteUpdate = (
  {
    courseId,
    studentId,
    note,
  }: { courseId: string; studentId: string; note: string },
  token: string,
) => {
  return authInstance().put<CoursePupilNoteResponse>(
    `/courses/${courseId}/notes/${studentId}`,
    { note },
    createAuthHeaders(token),
  );
};

const getBatchNotes = (
  { courseId, pupilIds }: { courseId: string; pupilIds: number[] },
  token: string,
) => {
  return authInstance().post(
    `/courses/${courseId}/notes/batchNotes`,
    pupilIds,
    createAuthHeaders(token),
  );
};

const note = {
  get: noteGet,
  update: noteUpdate,
  getBatch: getBatchNotes,
};

export default note;
