import { authInstance, createAuthHeaders } from "lib/api/utils";

const listActivities = (token: string) => {
  return authInstance().get<Array<DBCourseActivity>>(
    `/teachers/activities/list`,
    createAuthHeaders(token),
  );
};

const listCourses = (token: string) => {
  return authInstance().get<Array<DBCourse>>(
    `/teachers/courses/list`,
    createAuthHeaders(token),
  );
};

const teachers = {
  listActivities,
  listCourses,
};

export default teachers;
