import media from "./media";
import note from "./note";
import activities from "./activities";
import activity from "./activity";
import material from "./material";
import course from "./course";
import classroom from "./classroom";
import invite from "./invite";
import school from "./school";
import plan from "./plan";
import llmRoles from "./llmRoles";
import role from "./role";
import user from "./user";
import admin from "./admin";
import teachers from "./teachers";
import metabase from "./metabase";

const api = {
  admin,
  user,
  role,
  llmRoles,
  plan,
  school,
  invite,
  classroom,
  course,
  material,
  activity,
  activities,
  note,
  media,
  teachers,
  metabase,
};

export default api;
