import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import { CloudUpload } from "@mui/icons-material";
import styled from "@emotion/styled";
import formatBytes from "utils/bytesToReadable";
import api from "lib/api";
import { setSnackbar } from "reducers/ui";
import Modal from "components/Modal";
import { useAuth0 } from "@auth0/auth0-react";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const MAX_MATERIALS_SIZE_BYTES = 32e6;

const UploadMaterialModal = (props: any) => {
  const { getAccessTokenSilently } = useAuth0();
  const {
    courseId,
    currentSchoolId,
    currentClassroomTeacherId,
    selectedMaterial,
    open,
    onClose,
    activityId,
    concatMaterials,
  } = props;

  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState("");
  const [name, setName] = useState("");
  const [type, setType] = useState<MaterialTypeEnum>("video");
  const [filename, setFilename] = useState("");
  const [extension, setExtension] = useState("");
  const [sizeBytes, setSizeBytes] = useState<number>(0);
  const [courseMaterials, setCourseMaterials] = useState<Array<any>>([]);

  const onSubmit = async () => {
    try {
      const token = await getAccessTokenSilently();
      const materials = [
        {
          link,
          name,
          type,
          activityId: activityId ? activityId : null,
          coursesId: courseId,
          sizeBytes,
        },
      ];
      const data = {
        schoolId: currentSchoolId,
        classroomTeacherId: currentClassroomTeacherId,
        courseId,
        materials,
        removeMaterials: Array<number>(),
      };

      if (selectedMaterial) {
        data.removeMaterials = [selectedMaterial.id];
        await api.course.updateActivitiesAndMaterials(data, token, 0);
      } else {
        const material = await api.material.create(
          { material: materials[0] },
          token,
        );

        concatMaterials([material.data.material]);
      }

      setLink("");
      setName("");
      setType("video");
      setFilename("");
      setExtension("");

      onClose();
    } catch (e: any) {
      props.setSnackbar({ open: true, message: e.message, severity: "error" });
    }
  };

  const getType = (ext: string) => {
    switch (ext) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return "image";
      case "pdf":
      case "docx":
        return "document";
      default:
        throw new Error(`${ext} is an unsupported file type`);
    }
  };

  const handleOnChangeFile = async (e: any) => {
    setLink("");
    const newFile = e?.target?.files[0];
    if (newFile) {
      const formData = new FormData();
      formData.append("files", newFile);

      setLoading(true);
      try {
        const totalCourseBytes = courseMaterials.reduce(
          (acc, el) => acc + el.sizeBytes,
          0,
        );
        if (totalCourseBytes + newFile.size > MAX_MATERIALS_SIZE_BYTES) {
          props.setSnackbar({
            open: true,
            severity: "error",
            message: `This file is too large to be uploaded. The total size of this activity's materials (including its course materials) may not exceed 32mb. This activity currently has ${formatBytes(totalCourseBytes)}, and this file is ${formatBytes(newFile.size)}`,
          });
          return;
        }

        const token = await getAccessTokenSilently();
        const response = await api.media.upload(
          {
            schoolId: currentSchoolId,
            formData,
          },
          token,
        );

        setLink(response.data.links[0].link);
        setFilename(newFile.name.split(".").shift());
        setType(getType(newFile.name.split(".").pop()));
        setExtension(newFile.name.split(".").pop());
        setSizeBytes(newFile.size);
      } catch (e: any) {
        props.setSnackbar({
          open: true,
          severity: "error",
          message: e.message,
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = () => {
    onClose();

    setTimeout(() => {
      setLink("");
      setName("");
      setType("video");
      setFilename("");
      setExtension("");
      props.setSelectedMaterial();
    }, 500);
  };

  useEffect(() => {
    setLink(selectedMaterial?.link || "");
    setName(selectedMaterial?.name || "");
    setType(selectedMaterial?.type || "video");
    setFilename(selectedMaterial?.filename || "");
    setExtension(selectedMaterial?.extension || "");
  }, [selectedMaterial]);

  useEffect(() => {
    const getCourse = async () => {
      const token = await getAccessTokenSilently();
      const course = await api.course.get(
        {
          courseId,
          // TODO add a limit to the # of materials that can be associated to a course
          // So we don't have to workaround the pagination in this way
          per_page_materials: 9999,
        },
        token,
      );

      setCourseMaterials(
        activityId
          ? course.data.materials.filter(
              (el: any) => el.activityId === activityId,
            )
          : course.data.materials,
      );
    };
    if (courseId) getCourse();
  }, []);

  return (
    <Modal
      disabled={loading || !name || !link}
      open={open}
      onClose={handleClose}
      onSubmit={onSubmit}
      title="Upload Material"
      submitText="Save"
      cancelText="Cancel"
    >
      <Typography fontFamily="Inter" fontSize={12} sx={{ maxWidth: 400 }}>
        Note: The documents will be truncated past 100 pages. Uploading large
        amounts of documents will result in increased latency on each chat
        message.
      </Typography>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            height: 250,
            width: 400,
            p: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size={200} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: 400,
            padding: 2,
          }}
        >
          {!selectedMaterial && (
            <>
              <Button
                sx={{ height: 50 }}
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUpload />}
              >
                <Typography fontFamily="Inter" fontSize={14} fontWeight="bold">
                  Upload PDF
                </Typography>
                <VisuallyHiddenInput
                  onChange={handleOnChangeFile}
                  type="file"
                  accept=".pdf"
                />
              </Button>
              {filename !== "" && extension !== "" && (
                <TextField
                  sx={{ flex: 1 }}
                  fullWidth
                  placeholder="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              )}
              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  disabled
                  sx={{ flex: 3 }}
                  fullWidth
                  placeholder="File Name"
                  value={filename}
                  onChange={(e) => setFilename(e.target.value)}
                />
                <TextField
                  disabled
                  sx={{ flex: 1 }}
                  fullWidth
                  placeholder="Ext."
                  value={extension}
                  onChange={(e) => setExtension(e.target.value)}
                />
              </Box>
            </>
          )}
          {filename === "" && extension === "" && (
            <>
              {!selectedMaterial && (
                <Typography textAlign="center">- OR -</Typography>
              )}
              <TextField
                fullWidth
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextField
                disabled={selectedMaterial}
                fullWidth
                label="Link"
                value={link}
                onChange={(e) => setLink(e.target.value)}
              />
            </>
          )}
        </Box>
      )}
    </Modal>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadMaterialModal);
