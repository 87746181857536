import React, { useState } from "react";
import { connect } from "react-redux";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { setSnackbar } from "reducers/ui";
import MaterialItem from "./MaterialItem";

const MaterialList = <T extends BaseCourseMaterialID>({
  setSelectedMaterial,
  toggleOpenDialog,
  addingActivity,
  materials,
  onRemoveMaterial,
  imported,
}: {
  setSelectedMaterial: (material: T | undefined) => void;
  activityId?: string;
  addingActivity?: boolean;
  toggleOpenDialog: () => void;
  materials: Array<T>;
  onRemoveMaterial: (material: T) => void;
  imported?: boolean;
}) => {
  return (
    <>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: 600,
            lineHeight: "19.36px",
            letterSpacing: "0.02em",
            textAlign: "left",
            display: "flex",
            mt: 2,
            mb: 1,
          }}
        >
          {imported
            ? "Imported Activity"
            : addingActivity
              ? "Activity"
              : "Course"}{" "}
          Materials
        </Typography>
      </Grid>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        {materials.map((material: any, index: number) => (
          <MaterialItem
            key={`material-item-${index}`}
            material={material}
            onClick={() => {
              setSelectedMaterial(material);
              toggleOpenDialog();
            }}
            onRemove={onRemoveMaterial}
          />
        ))}
      </Box>
      {addingActivity && (
        <Grid item xs={12}>
          <Button
            onClick={toggleOpenDialog}
            color="primary"
            variant="outlined"
            sx={{
              fontFamily: "Inter",
              fontSize: "14px",
              fontWeight: 600,
              borderRadius: 1,
            }}
            startIcon={<Add />}
          >
            Add Material
          </Button>
        </Grid>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSnackbar,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaterialList);
