import { authInstance, createAuthHeaders } from "lib/api/utils";
const createCourseActivity = (
  activity: ActivityCreateRequest,
  token: string,
) => {
  return authInstance().post<DBCourseActivity>(
    "/activities/",
    activity,
    createAuthHeaders(token),
  );
};

const activityGetAnalytics = (
  { activityId, pupilId }: { activityId: string; pupilId: string },
  token: string,
) => {
  return authInstance().get<LatestObjectivesSummaryResponse>(
    `/activities/${activityId}/analytics/${pupilId}`,
    createAuthHeaders(token),
  );
};

const activityGetBatchAnalytics = (
  { activityId, pupilIds }: { activityId: string; pupilIds: number[] },
  token: string,
) => {
  return authInstance().post<BatchAnalyticsResponse>(
    `/activities/${activityId}/analytics/batchObjAnalytics`,
    pupilIds,
    createAuthHeaders(token),
  );
};

const activityGetSentiments = (
  { activityId, pupilId }: { activityId: string; pupilId: string },
  token: string,
) => {
  return authInstance().get<CourseActivityObjectiveSentimentAnalyticsResponse>(
    `/activities/${activityId}/sentiment_analytics/${pupilId}`,
    createAuthHeaders(token),
  );
};

const activityGetBatchSentiments = (
  { activityId, pupilIds }: { activityId: string; pupilIds: number[] },
  token: string,
) => {
  return authInstance().post<{
    analytics: {
      [pupilId: number]: CourseActivityObjectiveSentimentAnalyticsResponse;
    };
  }>(
    `/activities/${activityId}/sentiment_analytics/batchSentiments`,
    pupilIds,
    createAuthHeaders(token),
  );
};

const activities = {
  createActivity: createCourseActivity,
  getAnalytics: activityGetAnalytics,
  getSentiments: activityGetSentiments,
  getBatchAnalytics: activityGetBatchAnalytics,
  getBatchSentiments: activityGetBatchSentiments,
};

export default activities;
