import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { setSnackbar } from "reducers/ui";
import { clearUser, setSelectedActivity } from "reducers/user";
import EmbeddedDashboard from 'components/teacher/EmbeddedDashboard';

const InstructorCourseViewReport = (props: any) => {
  const { courseId } = useParams<{ courseId: string }>();

  return (
    <Grid container sx={{ pt: 2, px: 2, mb: 2, justifyContent: "center" }}>
      <EmbeddedDashboard dashboardId={10} courseId={courseId} />
    </Grid >
  );
};

const mapStateToProps = (state: any) => ({
  currentSchoolId: state.user.currentSchoolId,
  currentClassroomId: state.user.currentClassroomId,
  currentClassroomTeacherId: state.user.currentClassroomTeacherId,
});

const mapDispatchToProps = {
  setSelectedActivity,
  setSnackbar,
  clearUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(InstructorCourseViewReport);
